import { useState, useEffect, useRef } from "react";
import "./style.css";
import DropDown from "./Dropdown";
import TextArea from "./Textarea";
import GenerateCountryList from "../utils/generateCountryList";
import GenerateStoreList from "../utils/generateStoreList";
import GenerateRoomList from "../utils/generateRoomList";
import timeHelper from "../utils/timeHelper";
import renderCheckBoxes from "../Helpers/renderCheckBoxes";
import config from "../../main-config.json";
import PropTypes from "prop-types";

const URL = config.failures_api_url;
const CHECKBOX_QUERY_SELECTOR = "input[type='checkbox']";

const TicketForm = ({ handleTicketCounter, pendingsStatus }) => {
    const initialState = {
        country: "GREECE",
        store: "Aigaleo GO",
        room: "JACK THE RIPPER",
        report: "",
        cause: "",
        assistant: "",
        client: "",
        isSolved: false,
        solution: "",
        createdDate: "",
        completedDate: "1970-01-01",
        status: "pending",
        department: "electronic",
        checkboxes: ["electronic", "other"],
        errorMessage: "",
        successMessage: "",
    };

    const [formState, setFormState] = useState(initialState);
    const formRef = useRef(null);

    // Reset form
    const resetForm = () => {
        setFormState(initialState);
        formRef.current.reset();
    };

    const handleCheckboxFilter = (e) => {
        const checkbox = e.target.name;
        document
            .querySelectorAll(CHECKBOX_QUERY_SELECTOR)
            .forEach((element) => {
                element.checked = false;
            });

        document
            .querySelectorAll(CHECKBOX_QUERY_SELECTOR)
            .forEach((element) => {
                if (element.name === checkbox) {
                    element.checked = true;
                    setFormState((prevState) => ({
                        ...prevState,
                        department: checkbox,
                    }));
                }
            });
    };

    const handleChange = (event) => {
        const { name, value } = event.target;

        if (name === "country") {
            setFormState((prevState) => ({
                ...prevState,
                country: value,
            }));
            let store = document.getElementsByName("store")[0].value;
            let room = document.getElementsByName("room")[0].value;
            setFormState((prevState) => ({
                ...prevState,
                store,
                room,
            }));
        } else if (name === "store") {
            let room = document.getElementsByName("room")[0].value;
            setFormState((prevState) => ({
                ...prevState,
                store: value,
                room,
            }));
        } else {
            setFormState((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    const handleCheckbox = (event) => {
        const { name, value } = event.target;
        let completedDate = timeHelper();

        if (name === "solution" && value.length > 0) {
            setFormState((prevState) => ({
                ...prevState,
                isSolved: true,
                solution: value,
                status: "completed",
                completedDate: completedDate,
            }));
        } else {
            setFormState((prevState) => ({
                ...prevState,
                isSolved: false,
                status: "pending",
                solution: "",
            }));
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const { report, assistant, client } = formState;

        if (report === "" || assistant === "" || client === "") {
            setFormState((prevState) => ({
                ...prevState,
                errorMessage: "Please fill the blanks",
                successMessage: "",
            }));
        } else {
            const formData = new FormData();

            Object.keys(formState).forEach((key) => {
                formData.append(key, formState[key]);
            });
            try {
                const response = await fetch(`${URL}/ticket/insertTicket.php`, {
                    method: "POST",
                    body: formData,
                });

                const result = await response.json();

                if (result.message === "success") {
                    handleTicketCounterForm();
                    resetForm();
                    setFormState((prevState) => ({
                        ...prevState,
                        successMessage: "Ticket successfully submitted!",
                    }));
                } else {
                    setFormState((prevState) => ({
                        ...prevState,
                        errorMessage: "A problem has occurred",
                        successMessage: "",
                    }));
                }
            } catch (error) {
                setFormState((prevState) => ({
                    ...prevState,
                    errorMessage: error.message,
                    successMessage: "",
                }));
            }
        }
    };

    const handleTicketCounterForm = () => {
        let newTicketCounter = 0;
        let oldTicketCounter = pendingsStatus;

        if (formState.solution === "") {
            newTicketCounter = oldTicketCounter + 1;
            handleTicketCounter(newTicketCounter);
        } else {
            handleTicketCounter(oldTicketCounter);
        }
    };

    useEffect(() => {
        document.querySelectorAll(CHECKBOX_QUERY_SELECTOR).forEach((item) => {
            if (item.name === "electronic") {
                item.checked = true;
            }
        });

        let currentDate = timeHelper();
        setFormState((prevState) => ({
            ...prevState,
            createdDate: currentDate,
        }));
    }, []);

    return (
        <div className='back'>
            <form
                className='formContainer'
                ref={formRef}>
                <div className='formItem'>
                    <DropDown
                        name='country'
                        defaultValue={formState.country}
                        handleChange={handleChange}>
                        <GenerateCountryList />
                    </DropDown>
                    <DropDown
                        name='store'
                        defaultValue={formState.store}
                        handleChange={handleChange}>
                        <GenerateStoreList
                            selectedCountry={formState.country}
                        />
                    </DropDown>
                    <DropDown
                        name='room'
                        defaultValue={formState.room}
                        handleChange={handleChange}>
                        {GenerateRoomList(formState.store)}
                    </DropDown>
                    <p>
                        DATE: <span>{formState.createdDate}</span>
                    </p>
                    <div id='ticketForm__checkbox'>
                        {renderCheckBoxes(
                            formState.checkboxes,
                            handleCheckboxFilter
                        )}
                    </div>
                </div>
                <div className='formItem-2'>
                    <div className='row'>
                        <TextArea
                            name='report'
                            handleChange={handleChange}
                        />
                        <TextArea
                            name='cause'
                            handleChange={handleChange}
                        />
                    </div>
                    <div className='row'>
                        <TextArea
                            name='assistant'
                            handleChange={handleChange}
                        />
                        <TextArea
                            name='client'
                            handleChange={handleChange}
                        />
                    </div>
                    <div className='solutionInput'>
                        <label className='label'>SOLUTION</label>
                        <input
                            autoComplete='true'
                            name='solution'
                            cols='10'
                            rows='5'
                            onChange={handleCheckbox}
                        />
                        <p className='checkBoxStyle'>
                            <input
                                autoComplete='true'
                                id='test1'
                                name='isSolved'
                                type='checkbox'
                                readOnly
                                checked={formState.isSolved}
                                onChange={handleCheckbox}
                            />
                            <label htmlFor='test1'></label>
                        </p>
                    </div>
                    <p className='successMessage'>{formState.successMessage}</p>
                    <p className='errorMessage'>{formState.errorMessage}</p>
                    <p
                        className='submitButton'
                        onClick={handleSubmit}>
                        SUBMIT
                    </p>
                </div>
            </form>
        </div>
    );
};

TicketForm.propTypes = {
    handleTicketCounter: PropTypes.func.isRequired,
    pendingsStatus: PropTypes.number.isRequired,
};

export default TicketForm;
